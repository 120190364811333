import { ProductCard } from '@/components/atomic/molecules';

const RenderProductCardsOrganism = ({ initialProducts = [] }) => (
  <div
    className='grid grid-cols-1 xs:auto-rows-fr xs:grid-cols-2 lg:grid-cols-3 justify-between
  gap-y-5 md:gap-6 xs:gap-x-4 px-5 md:px-0 pb-16 md:pb-0 mt-1 md:my-5px mx-0 md:mx-5px'
  >
    {initialProducts.length > 0 &&
      initialProducts.map((product) => (
        <ProductCard
          key={product.id}
          {...product}
        />
      ))}
  </div>
);

export default RenderProductCardsOrganism;
