import Image from 'next/image';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';

import { DesktopSideBarFilters } from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const CategorySideBarMenu = ({
  categories,
  categorySlug,
  currentCategory,
  parentCategoryName,
  parentSlug,
  priceRange,
  searchkit,
  subCategorySlug
}) => {
  const [listedCategories, setListedCategories] = useState([]);
  const [showCategoriesList, setShowCategoriesList] = useState(
    !!(parentSlug || categorySlug || subCategorySlug)
  );
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const checkCategory = () =>
    categories.length > listedCategories.length
      ? `Show ${categories.length - (selectedCategoryIndex || 5)}more`
      : 'Show less';

  const findCurrentCategoryIndex = () =>
    categories &&
    categories.length &&
    categories.findIndex((category) => category.slug === categorySlug);

  useEffect(() => {
    const selectedIndex = findCurrentCategoryIndex();
    setSelectedCategoryIndex(selectedIndex >= 0 ? selectedIndex + 1 : 0);
    if (selectedIndex >= 0) {
      setListedCategories(categories.slice(0, selectedIndex + 1));
    } else {
      setListedCategories(categories.slice(0, 5));
    }
  }, [categories]);

  const handleCategoriesToggle = (e) => {
    e.preventDefault();
    setListedCategories((prevState) =>
      prevState.length < categories.length
        ? categories
        : categories.slice(
          0,
          selectedCategoryIndex > 0 ? selectedCategoryIndex : 5
        )
    );
  };

  const RenderCategoriesList = ({ categoryList }) => {
    const RenderParentCategoryLabel = () => (
      <div
        className='flex mb-3 items-center cursor-pointer transition-all ease-in-out duration-700'
        onClick={() => setShowCategoriesList((prevState) => !prevState)}
      >
        <Image
          alt={showCategoriesList ? 'Down Arrow' : 'Left Arrow'}
          height={12}
          src={`${staticMediaStoreBaseURL}/icons/${
            showCategoriesList ? 'down-arrow' : 'left-grey-arrow'
          }.svg`}
          width={12}
        />
        <div
          className={`cursor-pointer md:ms-3 font-medium text-base hover:text-cinnabar truncate leading-5 ${
            parentSlug === currentCategory.slug
              ? 'text-cinnabar'
              : 'text-dim-gray'
          }`}
        >
          {parentCategoryName}
        </div>
      </div>
    );

    return (
      <Fragment>
        {parentSlug !== currentCategory.slug ? (
          <Link
            href={getPageURL({
              pageName: ROUTE_CONFIG.L0_CATEGORY.label,
              pathParams: {
                parentSlug
              }
            })}
          >
            <RenderParentCategoryLabel />
          </Link>
        ) : (
          <RenderParentCategoryLabel />
        )}
        <ul className='h-auto'>
          {showCategoriesList &&
            categoryList.map((category) => (
              <li key={category.id}>
                <div className='flex mb-3 items-center ms-3 cursor-pointer'>
                  {category.childCategories.length ? (
                    <Image
                      alt={
                        category.slug === categorySlug
                          ? 'Down Arrow'
                          : 'Left Arrow'
                      }
                      height={12}
                      src={`${staticMediaStoreBaseURL}/icons/${
                        category.slug === categorySlug
                          ? 'down-arrow'
                          : 'left-grey-arrow'
                      }.svg`}
                      width={12}
                    />
                  ) : (
                    ''
                  )}

                  <Link
                    href={getPageURL({
                      pageName: ROUTE_CONFIG.L1_CATEGORY.label,
                      pathParams: {
                        parentSlug,
                        categorySlug: category.slug
                      }
                    })}
                  >
                    <div
                      className={`cursor-pointer md:ms-3 font-medium text-base hover:text-cinnabar truncate leading-5 ${
                        currentCategory.slug === category.slug
                          ? 'text-cinnabar'
                          : 'text-dim-gray'
                      }`}
                    >
                      {category.name}
                    </div>
                  </Link>
                </div>

                {category.slug === categorySlug ? (
                  <div className='md:ms-8'>
                    {!!category?.childCategories?.length &&
                      category.childCategories.map((childCategory, index) => (
                        <Link
                          key={index}
                          href={getPageURL({
                            pageName: ROUTE_CONFIG.L2_CATEGORY.label,
                            pathParams: {
                              parentSlug,
                              categorySlug: category.slug,
                              subCategorySlug:
                                subCategorySlug !== childCategory.slug
                                  ? childCategory.slug
                                  : ''
                            }
                          })}
                        >
                          <div
                            className={`cursor-pointer text-sm font-medium mb-3 hover:text-cinnabar ms-1 leading-17px ${
                              subCategorySlug === childCategory.slug
                                ? 'text-cinnabar'
                                : 'text-dim-gray'
                            }`}
                          >
                            {childCategory.name}
                          </div>
                        </Link>
                      ))}
                  </div>
                ) : (
                  ''
                )}
              </li>
            ))}
        </ul>
      </Fragment>
    );
  };

  return (
    <div className='md:w-82 border-r border-gray me-6 md:sticky md:top-40 hidden md:block h-full'>
      <div className='h-max-90vh overflow-y-scroll no-scrollbar'>
        <div
          className='pb-4'
          id='categories'
        >
          <div className='my-4 border-b border-gray'>
            <Text
              {...{
                className:
                  'text-lg text-nero capitalize pb-4 block leading-5 font-medium select-none',
                content: 'Filters',
                HtmlTag: 'span'
              }}
            />
          </div>
          <div>
            <Text
              {...{
                className:
                  'text-base text-nero capitalize pb-4 block leading-5 font-medium select-none',
                content: 'categories',
                HtmlTag: 'span'
              }}
            />
          </div>

          {listedCategories ? (
            <RenderCategoriesList categoryList={listedCategories} />
          ) : (
            ''
          )}

          {showCategoriesList && (
            <span
              className='text-xs text-clearblue hover:text-nero block font-medium leading-3.5
          ms-9 cursor-pointer select-none'
              onClick={handleCategoriesToggle}
            >
              {categories.length > 5 ? checkCategory() : ''}
            </span>
          )}
        </div>

        <div
          className='border-t border-gray block'
          id='Price'
        >
          <DesktopSideBarFilters
            priceRange={priceRange}
            searchkit={searchkit}
          />
        </div>
      </div>
    </div>
  );
};

export default CategorySideBarMenu;
