// TODO: Faizan Refactor component.
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import {
  CartItemControl,
  ImageCarousel,
  ListingCartNotification
} from '@/components/atomic/atoms';
import { ProductPrice } from '@/components/atomic/nuclei';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { DEVICE_SURFACE } from '@/config/common';
import { getShortDescription } from '@/helpers/productHelper';
import { captureGTMEventSelectItem } from '@/lib/gtm';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const NewTabLink = ({ carouselImages, productSlug }) => (
  <Link
    href={getPageURL({
      pageName: ROUTE_CONFIG.PDP.label,
      pathParams: {
        slug: productSlug
      }
    })}
    passHref
  >
    <a
      className='cursor-pointer h-full'
      target='_blank'
    >
      <div className='h-44 md:h-50 w-full overflow-hidden relative content-start flex justify-center'>
        <ImageCarousel
          {...{
            height: 200,
            images: carouselImages,
            preventOnCarouselImageClick: true,
            productCardFlag: true,
            width: 320
          }}
        />
      </div>
    </a>
  </Link>
);

const CurrentTabLink = ({ carouselImages, handleProductClick }) => (
  <a
    className='cursor-pointer h-full'
    onClick={handleProductClick}
  >
    <div className='h-44 md:h-50 w-full overflow-hidden relative content-start flex justify-center'>
      <ImageCarousel
        {...{
          height: 200,
          images: carouselImages,
          preventOnCarouselImageClick: true,
          productCardFlag: true,
          width: 320
        }}
      />
    </div>
  </a>
);

const AddToCartNotification = ({ productQuantityCount }) => (
  <div className='md:absolute md:bottom-10 w-full block z-99'>
    <ListingCartNotification
      {...{
        alertMessage: `${productQuantityCount} Item(s) added to cart`
      }}
    />
  </div>
);

const ProductCard = ({ product, searchPage }) => {
  const {
    assignedToCategories,
    availableDiscount,
    id,
    name: productName,
    price: { price },
    productMedia,
    slug: productSlug,
    type
  } = product;

  const productLink = getPageURL({
    pageName: ROUTE_CONFIG.PDP.label,
    pathParams: { slug: productSlug }
  });

  const { surface } = useUIContext();
  const { asPath: pathName, push, query } = useRouter();

  const minBookable = product?.minBookable || 1;

  const [quantity, setQuantity] = useState(minBookable);
  const [productQuantityCount, setProductQuantityCount] = useState(null);

  const shortDescription = getShortDescription(product);

  const handleProductClick = () => {
    if (window.location.href) {
      captureGTMEventSelectItem({
        assignedToCategories,
        availableDiscount,
        id,
        name: productName,
        price,
        quantity,
        type
      });

      if (query) {
        push(pathName, productLink, { shallow: true });
      } else {
        push(productLink);
      }
    } else {
      push(productLink);
    }
  };

  const carouselImages = productMedia.map(({ sortOrder, media }) => ({
    sortOrder,
    url: media.url
  }));

  return (
    <>
      <div
        className='bg-white h-full rounded-lg md:max-w-full box-border shadow-category flex flex-col content-between
        place-content-between relative group'
        id='product-card'
      >
        {searchPage ? (
          <NewTabLink
            {...{
              carouselImages,
              productSlug
            }}
          />
        ) : (
          <CurrentTabLink
            {...{
              carouselImages,
              handleProductClick
            }}
          />
        )}
        <div className='p-4 self-end w-full cursor-pointer fade block lg:group-hover:hidden'>
          <h4 className='font-medium font-Montserrat text-nero leading-19px text-base md:leading-5 block pb-1.5'>
            {productName}
          </h4>
          <div className='text-xs text-10px font-Montserrat font-medium text-dim-gray leading-3 block'>
            {shortDescription}
          </div>
          <ProductPrice {...{ product }} />
        </div>
        {surface === DEVICE_SURFACE.WEB && (
          <CartItemControl
            {...{
              product,
              quantity,
              searchPage,
              setProductQuantityCount,
              setQuantity
            }}
          />
        )}
      </div>
      {productQuantityCount && (
        <AddToCartNotification
          {...{
            productQuantityCount
          }}
        />
      )}
    </>
  );
};

export default ProductCard;
