import { Fragment } from 'react';
import { Hits } from 'searchkit';

import { ProductCard } from '@/components/atomic/molecules';

const ProductListOrganism = ({ size, searchPage }) => {
  const HitItem = (props) => {
    const { _source: product } = props.result;
    return (
      <ProductCard
        key={product.productNumber}
        {...{
          product,
          searchPage
        }}
      />
    );
  };

  return (
    <Fragment>
      <Hits
        className='relative grid grid-cols-1 xs:auto-rows-fr xs:grid-cols-2 lg:grid-cols-3 justify-between gap-y-5 md:gap-y-6
        xs:gap-x-4 md:gap-x-6 px-5 md:px-0 pb-0 md:pb-0 mt-1 md:my-5px'
        hitsPerPage={size}
        itemComponent={HitItem}
      />
    </Fragment>
  );
};

export default ProductListOrganism;
