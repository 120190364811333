import {
  NextButton,
  OtherButton,
  PreviousButton
} from '@/components/atomic/atoms';

const propsButtons = {
  Next: NextButton,
  Previous: PreviousButton
};

const RenderPagination = ({ onClick, label, active }) => {
  const handleClick = () => {
    onClick();
    window.scrollTo(0, 0);
  };
  const ComponentName = propsButtons[label];
  if (ComponentName) {
    return <ComponentName {...{ handleClick, label }} />;
  }
  if (label !== '...') {
    return <OtherButton {...{ handleClick, label, active }} />;
  }
  return '';
};

export default RenderPagination;
