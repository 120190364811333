import { debounce } from 'debounce';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useRef, useState } from 'react';
import url from 'url';

import {
  CallIcon,
  HeadingWithBackAndDownArrow,
  ProductCategories,
  ProductSubCategory,
  SearchIcon,
  SearchModal,
  WhatsappSupportIcon
} from '@/components/atomic/atoms';
import { CartView } from '@/components/atomic/molecules';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from '@/config/common';
import { HOST_PORTAL_URL } from '@/services/connections.service';

const CategoryMobileHeaderOrganism = ({
  categories,
  categorySlug,
  handleModal,
  heading,
  paramData,
  parentSlug,
  searchedValue,
  showOnScroll,
  showProductSubCategory,
  subCategories,
  subCategorySlug
}) => {
  const [showProductCategory, setShowProductCategory] = useState(true);
  const [showCartSelectorModal, setShowCartSelectorModal] = useState(false);
  const { cartList, cartItemCount, defaultCart, selectedCart } = useUIContext();
  const [showSearchModal, setShowSearchModal] = useState(false);

  const Router = useRouter();
  const { pathname } = url.parse(HOST_PORTAL_URL + Router.asPath, true);

  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const scrollYPosition = useRef(0);

  const handleScroll = () => {
    if (scrollYPosition.current !== window.pageYOffset) {
      const updateIsScrollingUp = scrollYPosition.current > window.pageYOffset;
      setIsScrollingUp(updateIsScrollingUp);
    }
    scrollYPosition.current = window.pageYOffset;
  };

  const debounceScrollHandler = debounce(handleScroll, 30);

  useEffect(() => {
    window.addEventListener('scroll', debounceScrollHandler);
    return () => window.removeEventListener('scroll', debounceScrollHandler);
  }, [debounceScrollHandler]);

  const handleBack = () => Router.back();

  useEffect(() => {
    setShowProductCategory(pathname !== '/categories/food-and-beverages');
  }, [Router.asPath, pathname]);

  return (
    <Fragment>
      <Head>
        <title>{paramData.metaTitle}</title>
        <meta
          name='description'
          content={paramData.metaDescription}
        />
        <meta
          property='og:title'
          content={paramData.ogTitle}
        />
        <meta
          property='og:description'
          content={paramData.ogDescription}
        />
        {paramData.ogImage ? (
          <meta
            property='og:image'
            content={`${mediaStoreBaseURL}/${paramData.ogImage}`}
            key='ogimage'
          />
        ) : (
          <meta
            property='og:image'
            content={`${staticMediaStoreBaseURL}/icons/hafla-icon.png`}
            key='ogimage'
          />
        )}
      </Head>

      <div
        className={`${
          showOnScroll && isScrollingUp
            ? 'bg-white sticky slide-down top-0 w-full z-99 transition-all'
            : 'bg-white'
        }`}
      >
        <div className='md:hidden justify-between flex px-4 h-12.5 items-center'>
          <HeadingWithBackAndDownArrow
            {...{ handleBack, handleModal, heading }}
          />

          <div className='flex items-center justify-items-end'>
            <CallIcon {...{ onScrollNotInHomeScreen: false }} />

            <div className=''>
              <WhatsappSupportIcon />
            </div>
            <>
              <SearchIcon {...{ setShowSearchModal }} />
              {showSearchModal && (
                <SearchModal
                  {...{
                    setShowSearchModal,
                    searchedValue
                  }}
                />
              )}
            </>

            {showSearchModal && (
              <SearchModal
                {...{
                  setShowSearchModal,
                  searchedValue
                }}
              />
            )}

            <CartView
              {...{
                cartItemCount,
                cartList,
                defaultCart,
                onHomeScreenBanner: false,
                Router,
                selectedCart,
                setShowCartSelectorModal,
                showCartSelectorModal
              }}
            />
          </div>
        </div>

        {showProductCategory && (
          <ProductCategories
            {...{
              categorySlug,
              parentSlug,
              categories
            }}
          />
        )}

        {showProductSubCategory && (
          <ProductSubCategory
            {...{
              parentSlug,
              categorySlug,
              subCategories,
              subCategorySlug
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

export default CategoryMobileHeaderOrganism;
